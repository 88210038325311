import React from 'react'

import Container from '../container'

import './style.css'

const Footer = () => (
  <footer className="footer" id="footer">
    <Container>
      <article>
        <p> Copyright © 2018 AO Architectural Design. All rights reserved.</p>
      </article>
    </Container>
  </footer>
)

export default Footer
