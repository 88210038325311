import React from 'react'
import { Link } from 'gatsby'
import logo from '../../images/AO-GREY-(162x91).png'

import './style.css'

const Header = () => (
  <header className="header" id="header">
    <div className="header-bg"></div>
    
    <article>
      <Link to="/" > 
        <img src={logo} alt="Company Logo"></img> 
        <h1>AO ARCHITECTURAL DESIGN</h1>
      </Link>
    </article>

    <article className="page-links">
      <Link to="/about"> <h3>ABOUT</h3> </Link>
      <Link to="/work"> <h3>WORK</h3> </Link>
      <Link to="/services"> <h3>SERVICES</h3> </Link>
      <Link to="/contact"> <h3>CONTACT</h3> </Link>
    </article>
    
  </header>
)

export default Header
